/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Module Button Layer
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ButtonLayer extends Component
{
    static propTypes = {
        device: PropTypes.any,              // DeviceTool.screenSize
        type: PropTypes.string,
        id: PropTypes.string,
        className: PropTypes.string,
        backgroundColor: PropTypes.string,
        text: PropTypes.string,
        html: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
        baseFontSize: PropTypes.number,
        fontSize: PropTypes.number,
        textAlign: PropTypes.string,
        lineHeight: PropTypes.string,
        onClick: PropTypes.func,
        hasLazyLoaded: PropTypes.bool,
    }
    
    static defaultProps = {
        type: "ButtonLayer",
        className: "",
        backgroundColor: null,
        text: null,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        baseFontSize: -1,
        fontSize: 100,
        textAlign: "left"
    }

    constructor(props)
    {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick()
    {
        const { onClick: clk } = this.props;

        clk && clk();
    }

    render()
    {
        const { 
            device,
            id,
            classes,
            children,
            backgroundColor,
            text, html, fontSize,
            baseFontSize,
            textAlign, lineHeight,
            x, y, width, height,
        } = this.props;

        let styles = {}, innerStyles = {};
        if (device.portrait)
        {
            styles = { backgroundColor }
        }
        else 
        {
            styles = {
                backgroundColor,
                transform: `translate(${x}px, ${y}px)`,
                width: `${width}px`,
                height: `${height}px`,
                textAlign: textAlign,
            }

            if (baseFontSize !== -1) styles.fontSize = baseFontSize + "px";
            if (lineHeight) styles.lineHeight = lineHeight;

            innerStyles = {
                fontSize: `${fontSize}%`
            }
        }

        const classItems = classNames("m-button-layer", "button", classes, {});
        const _html = html ? { __html: html } : null;

        return (
            <Fragment>
                {<button className={classItems} style={styles} tabIndex={0} aria-labelledby={id} onClick={this.onClick}>
                    {_html && <div id={id} className="inner-text-layer" dangerouslySetInnerHTML={_html} style={innerStyles}></div>}
                    {(text || children) && <div id={id} className="inner-text-layer" style={innerStyles}>{text ? text : children}</div>}
                </button>}
            </Fragment>
        )
    }
}
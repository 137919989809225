/**
 *
 * Book Your Stay Page
 * 
*/

import React, { Fragment } from "react"

import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import ButtonLayer from "../module/layers/ButtonLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    bookStayData, 
} from "../../data/PageAssets";

export default class BookYourStayPage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                        // lazy loading 
        this._assetPath = "bookyourstay";           // asset folder for page
        this._assets = bookStayData();              // asset data
        
        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false,
            hasResized: false,
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { hasLoaded, hasLazyLoaded } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded: lz } = this.state;
        const { hasResized: rz } = this.props;

        const getVid = this.pVideoNam("book_loop");
        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page bookyourstay"}>
                    <div className={"page-content"}>
                        <h1>Book Your Stay | A new concept in luxury rehabilitation, Casa Lupita is an all-inclusive and fully accessible 
                        4 Bedroom, 6 Baths villa designed to accommodate 9 Guests.</h1>
                        <p>You may book your stay through our reservation agents, My Huatulco Vacation</p>
                        <p>Established In 2008, My Huatulco Vacation is the leading vacation rental agency in the region. They make 
                        it a priority to offer on-call concierge service to our guests for the duration of their stay.  Their 
                        free/downloadable My Huatulco Vacation App for iPhone and Android offers all your vacation information in one centralized and accessible format.</p>
                        <p>The APP includes local recommendations, tour details, relevant local contacts, maps, and more. Whether you are inquiring about a tour or 
                        locating a restaurant as you venture into town, they will be there to assist you.</p>
                        <h2>Payment and Cancellation Terms</h2>
                        <ul>
                            <li>Payment can be made by Visa, Mastercard, or money wire.</li>
                            <li>30% deposit on base rental rate due at time of booking + $95 USD admin fee | Balance + tax due 60 days prior to arrival.</li>
                            <li>If you have to cancel your original travel dates, you can move your dates without a change fee up to 30 days prior to arrival.</li>
                        </ul>
                    </div>
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps}>
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("book_hero_shot")}
                                    video={{ desktop: getVid, delay: this._isPhone ? this._delay : 0 }}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Book Your Stay"
                                    body={"A new concept in luxury rehabilitation, Casa Lupita is an all-inclusive and fully accessible 4 Bedroom, 6 Baths villa designed to accommodate 9 Guests."}
                                />
                                <div ref={this._scrollRef} className="module-scroll-down show-scroll"><ScrollDown /></div>
                            </Module>

                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps}>

                                <TextLayer 
                                    text={"You may book your stay through our reservation agents, My Huatulco Vacation"} 
                                    x={340} y={107} width={464} height={140}></TextLayer>
                                
                                <ButtonLayer 
                                    id={"book-now-01"}
                                    text={"Book Now"} 
                                    textAlign={"center"}
                                    onClick={(e) => {
                                        const url = "https://www.explorehuatulco.com";
                                        window.__raid.ga.trackEvent('Website', 'Book Now Link', url);
                                        window.open(url, "_blank")
                                    }}
                                    x={512} y={205} width={178} height={60}></ButtonLayer>
                                
                                <TextLayer 
                                    classes="est-text"
                                    text={"Established In 2008, My Huatulco Vacation is the leading vacation rental agency in the region. They make it a priority to offer on-call concierge service to our guests for the duration of their stay.  Their free/downloadable My Huatulco Vacation App for iPhone and Android offers all your vacation information in one centralized and accessible format."} 
                                    x={340} y={353} width={464} height={370}></TextLayer>
                                
                                <ImageLayer 
                                    classes="my-huatulco"
                                    alt="My Huatulco Vacation Logo"
                                    asset={this.assetRef("my_huatulco_vacation")}
                                    x={1094} y={40} width={550} height={310}
                                />
                                
                                <TextLayer 
                                    text={"The APP includes local recommendations, tour details, relevant local contacts, maps, and more. Whether you are inquiring about a tour or locating a restaurant as you venture into town, they will be there to assist you."} 
                                    x={1094} y={405} width={536} height={270}></TextLayer>
                                
                                <ButtonLayer 
                                    id={"book-now-02"}
                                    text={"Book Now"} 
                                    textAlign={"center"}
                                    onClick={(e) => {
                                        const url = "https://www.explorehuatulco.com/Escapia/Details/2104-209429";
                                        window.__raid.ga.trackEvent('Website', 'Book Now Link', url);
                                        window.open(url, "_blank");
                                    }}
                                    x={1321} y={614} width={178} height={60}></ButtonLayer>

                                <ImageLayer 
                                    sendToBack={true}
                                    alt="Casa Lupita front view"
                                    asset={this.assetRef("bk_casa_lupita_front")}
                                    x={0} y={650} width={1920} height={1163}
                                />

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <TextLayer classes="bottom-space" x={264} y={99} width={1280} height={360} titleHtml={"Payment & Cancellation Terms"}>

                                    <Fragment>
                                        <ul>
                                            <li>Payment can be made by Visa, Mastercard, or money wire.</li>
                                            <li>30% deposit on base rental rate due at time of booking + $95 USD admin fee | Balance + tax due 60 days prior to arrival.</li>
                                            <li>If you have to cancel your original travel dates, you can move your dates without a change fee up to 30 days prior to arrival.</li>
                                        </ul>
                                    </Fragment>

                                </TextLayer>

                            </ModuleLayer>

                            <ModuleLayer
                                backgroundColor="white" 
                                {...mProps}
                                animate="push"
                            >
                                <ImageLayer 
                                    alt="Family enjoying the pool at Casa Lupita"
                                    asset={this.assetRef("bk_family_pool")}
                                    x={0} y={0} width={1920} height={1080}
                                />

                            </ModuleLayer>
                            
                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz} 
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>

                        </div>
                    }

                </article>
            </Fragment>
        )
    }  
}